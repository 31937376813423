<!--
 * @Descripttion: 
 * @version: 
 * @Author: Mr. Xu
 * @Date: 2021-04-01 17:11:04
 * @LastEditors: Mr. Xu
 * @LastEditTime: 2021-04-06 12:30:01
-->
<template>
  <div>
    <div class="query">
      <el-select v-model="channelVal" placeholder="请选择渠道">
        <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
        </el-option>
      </el-select>
      <div style="display: inline-block;margin-left:20px;">
        <el-date-picker v-model="timevalue" :popper-append-to-body="false" :editable="false" popper-class="timet_datepicker" align="center" size="small" type="datetimerange" value-format="timestamp" :clearable="false" range-separator="至" start-placeholder="开始日期" :picker-options="pickerOptions" end-placeholder="结束日期" @change="changeTimec()" :default-time="defaultTime"></el-date-picker>
      </div>
      <div style="display: inline-block;margin-left:20px;">
        <el-button type="primary" @click="queryFun()">查询</el-button>
      </div>
    </div>
    <ChannelReport ref="ChannelReport"></ChannelReport>
    <statementAnalysis ref="statementAnalysis" :obj="obj"></statementAnalysis>
  </div>
</template>

<script>
// import search from "./com/search.vue";
// import adddialog from "./com/addDialog"
import ChannelReport from "./com/ChannelReport.vue"; //各渠道报表
import statementAnalysis from "./com/statementAnalysis.vue"; //渠道报表分析
export default {
  components: {
    ChannelReport,
    statementAnalysis,
  },
  props: {},
  data() {
    return {
      baseUrl: window.cfg.cutPhotoUrl,
      options: [],
      channelVal: "tencent",
      defaultTime: ["00:00:00", "23:59:59"], //日期时间
      timevalue: [],
      pickerOptions: {},
      stime: "",
      etime: "",
      dateTime: "", //选择的时间范围
      obj: {
        channelVal: this.channelVal,
        stime: this.stime,
        etime:this.etime,
      },
    };
  },
  watch: {},
  computed: {},
  methods: {
    //获取渠道列表数据
    getChannel() {
      let { baseUrl } = this;
      $.ajax({
        url: `${baseUrl}/frameStatic/channelParam`,
        type: "GET",
        dataType: "JSON",
        contentType: "application/json",
        data: {},
        success: (data) => {
          // console.log(data.data);
          if (data.code == "00000") {
            this.options = data.dataList;
          }
        },
        error: (err) => {
          console.log(999999999);
        },
      });
    },
    //获取当天时间的默认值
    getDate() {
      const start = new Date(new Date().toLocaleDateString());
      start.setTime(start.getTime());
      var end = new Date(
        new Date(new Date().toLocaleDateString()).getTime() +
          24 * 60 * 60 * 1000 -
          1
      );
      this.windowetime = Math.round(end.getTime() / 1000);
      this.stime = start.getTime();
      this.etime = Math.round(end.getTime());
      var arr = [start.getTime(), Math.round(end.getTime())];
      this.timevalue = arr;
    },
    //选择时间
    changeTimec() {
      var startTime = "",
        endTime = "",
        newendTime = "";
      //   console.log(this.timevalue);
      if (this.timevalue == null) {
        this.stime = startTime;
        this.etime = newendTime;
      } else {
        startTime = this.timevalue[0];
        endTime = this.timevalue[1];
        newendTime = Math.round(endTime);
        this.stime = startTime;
        this.etime = newendTime;
      }
     
    },
    //查询
    queryFun() {
      let obj = {
        channelVal: this.channelVal,
        stime: this.stime,
        etime: this.etime,
      };
      this.$refs.ChannelReport.getData(obj);
      this.$refs.statementAnalysis.init(obj);//渠道报表分析
    },
    handleCurrentChange() {},

    addGoods() {
      // this.$refs.addDialog.showDialog();
    },
  },
  created() {},
  mounted() {
    this.getChannel();
    this.getDate();
    let obj = {
      channelVal: this.channelVal,
      stime: this.stime,
      etime: this.etime,
    };
    this.$refs.ChannelReport.getData(obj);
    this.$refs.statementAnalysis.init(obj);
  },
};
</script>
<style lang="scss" scoped>
.query {
  background: #fff;
  padding: 16px 16px;
  margin-bottom: 20px;
}
.goodsList {
  .tableBox {
    margin-top: 20px;
    color: #000;
  }
  .pageBox {
    text-align: right;
    padding: 10px;
    background: #fff;
  }
  .rowClassName {
    height: 40px;
  }
}
</style>