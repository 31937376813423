<template>
  <div class="secondlevelTable">
    <el-dialog title="子订单" :visible.sync="centerdelet" width="80%" :close-on-click-modal="false" :before-close="handleClose">
      <div class="content">
         <div class="export_btn">
      <el-button type="primary" icon="el-icon-document" @click="exportBtn()"> 导出 Excel </el-button>
    </div>
        <el-table :data="tableData" style="width: 100%" height="400" highlight-current-row>
          <el-table-column prop="orderId" label="序号" width="50" align="center">
            <template slot-scope="scope">
              <span>{{ scope.$index + 1 }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="childOrderId" label="子订单ID" align="center">
          </el-table-column>
          <el-table-column prop="orderStatus" label="订单状态" align="center">
          </el-table-column>
          <el-table-column prop="failFrameCutNum" label="失败截帧数" align="center">
          </el-table-column>
          <el-table-column prop="failList" label="失败截帧列表" align="center">
          </el-table-column>
          <el-table-column prop="totalFrameCutNum" label="总截帧数" align="center">
          </el-table-column>
          <el-table-column prop="totalList" label="总截帧列表" align="center">
          </el-table-column>
        </el-table>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="centerdelet = false">取 消</el-button>
        <el-button type="primary" @click="sure()" style="margin-left:40px;">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import dayjs from "dayjs";
export default {
  components: {},
  props: {},
  data() {
    return {
      baseUrl: window.cfg.cutPhotoUrl,
      centerdelet: false,
      tableData: [],
      id:""
    };
  },
  watch: {},
  computed: {},
  methods: {
    showDialog(id) {
      this.centerdelet = true;
      this.getDetail(id);
    },
    sure() {
      this.$emit("sureEvent");
      this.centerdelet = false;
    },
    //查看
    // checkItem(item) {
    //   this.$emit("sureEvent", item);
    // },
    handleClose() {
        this.centerdelet = false;
    },
    //获取二级表格
    getDetail(id) {
      let { baseUrl } = this;
      console.log(id);
      this.id=id
      $.ajax({
        url: `${baseUrl}/frameCutOrder/staticChildList`,
        type: "GET",
        dataType: "JSON",
        contentType: "application/json",
        data: {
          id: id,
        },
        success: (data) => {
          console.log(data.dataList);
          if (data.code == "00000") {
            data.dataList.map((item, index) => {
              item.totalList = item.totalList.toString();
            });
            this.tableData = data.dataList;
            console.log(this.tableData);
          }
        },
        error: (err) => {
          console.log(999999999);
        },
      });
    },
      exportBtn() {
      console.log(this.objVal);
      let { baseUrl } = this;
      let href = `${baseUrl}/frameCutOrder/staticChildListExport?id=${this.id}`;
      var a = document.createElement("a");
      a.href = href;
      a.target = "_blank";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    },
  },
  created() {},
  mounted() {},
};
</script>
<style lang="scss" scoped>
.alertdelet {
  .el-dialog__title {
    margin-left: 10px !important;
  }
  .el-dialog {
    width: 360px !important;
    height: 230px !important;
    border-radius: 8px;
    padding-bottom: 20px;
    text-align: center;
    font-size: 14px;
  }
  .delehint {
    vertical-align: middle;
    margin-left: 10px;
    display: inline-block;
    width: 225px;
    text-align: left;
  }
  .dialog-footer {
    text-align: center;
  }
  .dele_desc {
    margin-top: 20px;
  }
}
</style>