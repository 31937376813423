<!--
 * @Descripttion: 
 * @version: 
 * @Author: Mr. Xu
 * @Date: 2021-04-01 17:11:04
 * @LastEditors: Mr. Xu
 * @LastEditTime: 2021-04-06 12:30:01
-->
<template>
  <div class="goodsList">
    <h3>渠道报表分析</h3>
    <div class="export_btn">
      <el-button type="primary" icon="el-icon-document" @click="exportBtn()"> 导出 Excel </el-button>
    </div>
    <div class="tableBox">
      <el-table :data="tableData" stripe style="width: 100%" height="500" row-class-name="rowClassName" :highlight-current-row="true">
        <el-table-column prop="channel" label="渠道">
        </el-table-column>
        <el-table-column prop="orderId" label="订单id">
        </el-table-column>
        <el-table-column prop="deviceId" label="设备id"> </el-table-column>
        <el-table-column prop="tid" label="tid"> </el-table-column>
        <el-table-column prop="receiveImgNum" label="接收图片数"></el-table-column>
        <el-table-column prop="pushImgNum" label="推送图片数"> </el-table-column>
        <el-table-column prop="pushFlow" label="推送流量"> </el-table-column>
        <el-table-column prop="settlementFlow" label="结算流量"> </el-table-column>
        <el-table-column label="操作" width="80">
          <template slot-scope="scope">
            <el-button type="text" @click="check(scope.row)">查看</el-button>
          </template>
          <!-- <el-button type="text">删除</el-button> -->
        </el-table-column>
      </el-table>
      <div class="pageBox">
        <el-pagination @current-change="handleCurrentChange" :current-page="currentPage" :page-size="pageSize" :total="pageTotal" background layout="prev, pager, next">
        </el-pagination>
      </div>
    </div>
    <dialogTalbe ref="dialogTalbe" @sureEvent="sureEvent"></dialogTalbe>
  </div>
</template>

<script>
// import search from "./com/search.vue";
// import adddialog from "./com/addDialog"
import dialogTalbe from "./dialogTalbe.vue";
export default {
  components: { dialogTalbe },
  props: {
    // obj: {
    //   type: Object,
    //   required: true,
    // },
  },
  data() {
    return {
      baseUrl: window.cfg.cutPhotoUrl,
      tableData: [],
      currentPage: 1,
      pageSize: 10,
      pageTotal: 0,
      itemId: "",
      dialogDetail: false,
      objVal: {},
    };
  },
  watch: {
    obj(val) {
      console.log(val);
    },
  },
  computed: {},
  methods: {
    //翻页
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getData(val);
    },
    //获取数据
    getData(val) {
      let { baseUrl } = this;
      console.log(val);

      let obj = {
        channel: this.objVal.channelVal,
        startTime: this.objVal.stime,
        endTime: this.objVal.etime,
        page: val,
      };
      $.ajax({
        url: `${baseUrl}/frameCutOrder/staticList`,
        type: "GET",
        dataType: "JSON",
        contentType: "application/json",
        data: obj,
        success: (data) => {
          console.log(data);
          if (data.code == "00000") {
            //截帧数
            this.tableData = data.dataList;
            this.pageTotal = data.totalCount;
            // this.perData.data = data.dataList;
            // this.perData.xaxis = data.data.date;
          }
          console.log(this.tableData);
        },
        error: (err) => {
          console.log(999999999);
        },
      });
    },
    init(obj) {
      this.objVal = obj;
      this.getData(1)
    },
    sureEvent(item) {
      console.log(item);

      this.dialogDetail = true;

      this.objVal = item;
      this.itemId = item.id;
      this.getData(1);
    },
    check(item) {
      this.$refs.dialogTalbe.showDialog(item.id);
    },
    exportBtn() {
      console.log(this.objVal);
      let { baseUrl } = this;
      let href = `${baseUrl}/frameCutOrder/staticListExport?channel=${this.objVal.channelVal}&startTime=${this.objVal.stime}&endTime=${this.objVal.etime}`;
      var a = document.createElement("a");
      a.href = href;
      a.target = "_blank";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    },
  },
  created() {},
  mounted() {
    // this.getData();
  },
};
</script>
<style lang="scss" scoped>
.goodsList {
  margin-top: 20px;
  background: #fff;
  padding: 20px;
  border-radius: 20px;
  box-shadow: 3px 3px 5px #dcdfe6;
  h3 {
    color: #36a3f7;
    display: inline-block;
  }
  .export_btn {
    display: inline-block;
    margin-left: 30px;
  }
  .tableBox {
    margin-top: 20px;
    color: #000;
  }
  .pageBox {
    text-align: right;
    padding: 10px;
    background: #fff;
  }
  .rowClassName {
    height: 40px;
  }
}
</style>