
<template>
  <div class="goodsList">
    <h3>各渠道报表</h3>
    <div class="export_btn">
      <el-button type="primary" icon="el-icon-document" @click="exportBtn()"> 导出 Excel </el-button>
    </div>

    <div class="tableBox">

      <el-table :data="tableData" stripe style="width: 100%" height="500" row-class-name="rowClassName" :highlight-current-row="true">
        <el-table-column prop="channel" label="渠道">
        </el-table-column>
        <el-table-column prop="dayTime" label="时间">
        </el-table-column>
        <el-table-column prop="sunGpsPortsCollect" label="采集时段轨迹点数">
        </el-table-column>
        <el-table-column prop="sunsetGpsPortsCollect" label="非采集时段轨迹点数">
        </el-table-column>
        <el-table-column prop="gpsFlow" label="GPS结算流量"> </el-table-column>
        <el-table-column prop="backImgNum" label="回传图片数"> </el-table-column>
        <el-table-column prop="backImgFlow" label="回传图片原始流量"> </el-table-column>
        <el-table-column prop="specImgNum" label="符合规格图片数"> </el-table-column>
        <el-table-column prop="specImgFlow" label="符合规格图片流量"> </el-table-column>
        <el-table-column prop="outRangeImgNum" label="超出规格图片张数"> </el-table-column>
        <el-table-column prop="outRangeImgFlow" label="超标流量"> </el-table-column>
        <el-table-column prop="outRangeImgsFlow" label="超出规格图片原始流量"> </el-table-column>
        <el-table-column prop="outRangeImgbFlow" label="超出规格图片标准计算流量"> </el-table-column>
        <el-table-column prop="payFlow" label="结算图片流量"> </el-table-column>

        <el-table-column prop="payFlowSun" label="结算流量"> </el-table-column>

      </el-table>
      <!-- <div class="pageBox">
        <el-pagination @current-change="handleCurrentChange" :current-page="currentPage" :page-size="pageSize" :total="pageTotal" background layout="prev, pager, next">
        </el-pagination>
      </div> -->
    </div>

  </div>
</template>

<script>
// import search from "./com/search.vue";
// import adddialog from "./com/addDialog"
export default {
  components: {},
  props: {},
  data() {
    return {
      baseUrl: window.cfg.cutPhotoUrl,
      tableData: [],
      currentPage: 1,
      pageSize: 10,
      pageTotal: 0,
      excelArryLength: [
        "channel",
        "dayTime",
        "sunGpsPortsCollect",
        "sunsetGpsPortsCollect",
        "gpsFlow",
        "backImgNum",
        "backImgFlow",
        "specImgNum",
        "specImgFlow",
        "outRangeImgNum",
        "outRangeImgFlow",
        "outRangeImgsFlow",
        "outRangeImgbFlow",
        "payFlow",
        "payFlowSun", //经度
      ], //需要下载的字段

      tHeader: [
        "渠道",
        "时间",
        "采集时段轨迹点数",
        "非采集时段轨迹点数",
        "GPS结算流量",
        "回传图片数",
        "回传图片原始流量",
        "符合规格图片数",
        "符合规格图片流量",
        "超出规格图片张数",
        "超标流量",
        "超出规格图片原始流量",
        "超出规格图片标准计算流量",
        "结算图片流量",
        "结算流量",
      ], //下载的表头
    };
  },
  watch: {},
  computed: {},
  methods: {
    handleCurrentChange() {},
    //获取数据
    getData(val) {
      let { baseUrl } = this;
      // console.log(id);
      let obj = {
        channel: val.channelVal,
        startTime: val.stime,
        endTime: val.etime,
      };
      $.ajax({
        url: `${baseUrl}/frame/cutStatic/getChannelTanleData`,
        type: "POST",
        dataType: "JSON",
        contentType: "application/json",
        data: JSON.stringify(obj),
        success: (data) => {
          console.log(data);
          if (data.code == "200") {
            //截帧数
            data.dataList.map((item, index) => {
               //结算流量
              if(!item.payFlowSun){
                item.payFlowSun =0
              }
              item.payFlowSun = this.limit_fun(item.payFlow+item.gpsFlow);
              //结算图片流量
              item.payFlow = this.limit_fun(item.payFlow);
              //回传图片原始流量
              item.backImgFlow = this.limit_fun(item.backImgFlow);
              //符合规格图片流量
              item.specImgFlow = this.limit_fun(item.specImgFlow);
              //超出规格图片原始流量
              item.outRangeImgsFlow = this.limit_fun(item.outRangeImgsFlow);
              //超标流量
              item.outRangeImgFlow = this.limit_fun(item.outRangeImgFlow);

              item.outRangeImgbFlow = this.limit_fun(item.outRangeImgbFlow);
             
              //结算流量
              if (!item.gpsFlow) {
                item.gpsFlow = 0;
              }
              item.gpsFlow = this.limit_fun(item.gpsFlow);
              console.log(item)
            });
            this.tableData = data.dataList;
            this.pageTotal = data.totalCount;

            // this.perData.data = data.dataList;
            // this.perData.xaxis = data.data.date;
          }
          console.log(this.tableData);
        },
        error: (err) => {
          console.log(999999999);
        },
      });
    },
    limit_fun(val) {
      // let limit=(val/(1024 * 1024 * 1024)).toFixed(2) + "GB"
      let limit = val / (1024 * 1024 * 1024) + "GB";
      return limit;
    },
    //下载
    exportBtn() {
      if (this.tableData.length >= 1) {
        require.ensure([], () => {
          // const { export_json_to_excel } = require("../../../vendor/Export2Excel");
          const {
            export_json_to_excel,
          } = require("../../../vendor/Export2Excel");
          const tHeader = this.tHeader;
          const filterVal = this.excelArryLength;
          const list = this.tableData;
          const data = this.formatJson(filterVal, list);
          export_json_to_excel(tHeader, data, "各渠道报表excel");
        });
      } else {
        this.$message.error("最少选一项！");
      }
    },
    formatJson(filterVal, jsonData) {
      return jsonData.map((v) => filterVal.map((j) => v[j]));
    },
  },
  created() {},
  mounted() {
    //   this.getDate()
    //   this.getData()
  },
};
</script>
<style lang="scss" scoped>
.goodsList {
  background: #fff;
  padding: 20px;
  border-radius: 20px;
  box-shadow: 3px 3px 5px #dcdfe6;
  h3 {
    color: #36a3f7;
    display: inline-block;
  }
  .export_btn {
    display: inline-block;
    margin-left: 30px;
  }
  .tableBox {
    margin-top: 20px;
    color: #000;
  }
  .pageBox {
    text-align: right;
    padding: 10px;
    background: #fff;
  }
  .rowClassName {
    height: 40px;
  }
}
</style>